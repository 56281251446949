/*eslint-disable*/
import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "components/CustomInput/CustomInput.js";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import GetAppIcon from '@material-ui/icons/GetApp';

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
// sections for this page
import SectionDescription from "views/PresentationPage/Sections/SectionDescription.js";
import SectionFooter from "views/footer/PageFooter.js"

import logo from "assets/img/logo/supveto.jpg";
import imgSecMed from "assets/img/sup.jpg";

import presentationStyle from "assets/jss/material-kit-pro-react/views/echosup/AuxiliaireVetoStyle.js";

import logo_ecosup from "assets/img/ecosup.png";

import asv_bloc1 from "assets/img/asv_bloc/asv_bloc1-min.png";
import asv_bloc2 from "assets/img/asv_bloc/asv_bloc2-min.png";
import asv_bloc3 from "assets/img/asv_bloc/asv_bloc3-min.png";
import asv_bloc4 from "assets/img/asv_bloc/asv_bloc4-min.png";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(presentationStyle);

export default function AuxiliaireVetoPage() {
  React.useEffect(() => {
  window.scrollTo(0, 0);
  document.body.scrollTop = 0;
  retrieveSessions();
},[]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalDownload, setModalDownload] = React.useState(false);
  const [data, setData] = useState({ nom: "", prenom: "", email:"", phone:""});
  const [errors, setErrors] = useState({});

  function handleValidation(){
    let formIsValid = true;
    let errors = {};

    //Name
    let name = `${data.nom}`;
    if(name.trim() == ""){
       formIsValid = false;
       errors["name"] = "*Veuillez entrer un nom";
    }else{
      if(typeof name !== "undefined"){
         if(!name.match(/^[a-zA-Z]+$/)){
            formIsValid = false;
            errors["name"] = "*Le nom doit comporter uniquement des caratères";
         }
      }
    }

    //Prenom
    let prenom = `${data.prenom}`;
    if(prenom.trim() == ""){
       formIsValid = false;
       errors["prenom"] = "*Veuillez entrer un prénom";
    }else{
      if(typeof prenom !== "undefined"){
         if(!prenom.match(/^[a-zA-Z]+$/)){
            formIsValid = false;
            errors["prenom"] = "*Le prénom doit comporter uniquement des caratères";
         }
      }
    }

    //Email
    let email = `${data.email}`;
    if(email.trim() == ""){
       formIsValid = false;
       errors["email"] = "*Veuillez entrer une adresse email";
    }else{
      if(typeof email !== "undefined"){
         let lastAtPos = email.lastIndexOf('@');
         let lastDotPos = email.lastIndexOf('.');

         if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') == -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
            formIsValid = false;
            errors["email"] = "*Veuillez entrer une adresse email valide";
          }
     }
    }

    //Phone
    let phone = `${data.phone}`;
    if(phone.trim() == ""){
       formIsValid = false;
       errors["phone"] = "*Veuillez entrer un numéro de téléphone";
    }else{
      if(typeof phone !== "undefined"){
         if(!phone.match(/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/)){
            formIsValid = false;
            errors["phone"] = "*Veuillez entrer un numéro de téléphone valide";
         }
      }
    }

    if(!checkbox){
      formIsValid = false;
      errors["checkbox"] = "*Veuillez acceptez l'enregistrement de vos informations";
    }

   setErrors(errors);
   console.log(errors);
   return formIsValid;
  }

  const [checkbox, setCheckbox] = useState(false);
  const handleCheckbox = (event) => {
    errors["checkbox"]="";
    setCheckbox(event.target.checked);
  };

  function download() {
    let isValid = handleValidation();
    if(isValid){
      window.open(`${global.url_plaquette_supveto}`);
      setIsLoading(true);
      const dataForm = new FormData();
      dataForm.append('data', JSON.stringify({
           nom: `${data.nom}`,
           prenom: `${data.prenom}`,
           email: `${data.email}`,
           phone: `${data.phone}`,
           formation: 2
         }))

      axios.post(`${global.API_URL}/downloads`,dataForm
      ).then(function(response){
      console.log('Success!');
      setModalDownload(false);
      setIsLoading(false);
    })
    .catch(function(){
      console.log('Fail!');
    });
    }
  }

  const [sessions, setSessions] = useState([]);
  const [inscriptionEnabled, setInscriptionEnabled] = useState(false);
  function retrieveSessions()
  {
    let userjwt = localStorage.getItem('jwt');
    axios.get(`${global.API_URL}/session-informations`)
    .then(res => {

      let listSessions = [];
      for (let [index,session] of res.data.entries()) {
        console.log(session);

        const isFound = session.formations.some(element => {
          if (element.id === 2) {
            return true;
          }
        });

        if(isFound){
          let date = moment(session.date).format('DD/MM/yyyy');
          let time = moment(session.date).format('HH:mm');
          let s={text:date+" "+time,id:session.id};
          listSessions.push(s);
        }
      }
      if(listSessions.length>0)setInscriptionEnabled(true);
      setSessions(listSessions);
    });
  }

  const [sessionSelect, setSessionSelect] = React.useState("");
  const handleSessionSelect = event => {
    let code = event.target.value;
    setSessionSelect(event.target.value);
    console.log("Session : "+code);
  }

  const [modalInscription, setModalInscription] = React.useState(false);
  function registerRDV(){
    let isValid = handleValidation();
    if(isValid){
      setIsLoading(true);
      const dataForm = new FormData();
      dataForm.append('data', JSON.stringify({
           nom: `${data.nom}`,
           prenom: `${data.prenom}`,
           email: `${data.email}`,
           phone: `${data.phone}`,
           session_information: sessionSelect
         }))

      axios.post(`${global.API_URL}/rdv-sessions`,dataForm
      ).then(function(response){
      console.log('Success!');
      setModalInscription(false);
      setSuccessRegister(true);
      setIsLoading(false);
    })
    .catch(function(){
      console.log('Fail!');
    });
    }
  }

  const [successRegister, setSuccessRegister] = useState(false);
  const handleCloseRegister = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessRegister(false);
  };

  const classes = useStyles();
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 50,
          color: "white"
        }}
      />
      <Parallax
        image={require("assets/img/auxiliaire.jpg")}
        className={classes.parallax}
        filter="dark"
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <GridContainer justify="center" alignItems="center">
                  <GridItem xs={8} sm={4} md={4}>
                    <img
                      src={logo_ecosup} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                    <h2 className={classes.title}>
                      Auxiliaire de service vétérinaire
                    </h2>
                  </GridItem>
                </GridContainer>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={8} md={8}>
              <h3 className={classes.cardTitle}>Prérequis</h3>
              <h5 className={classes.description}>La formation d’auxiliaire de service vétérinaire est ouverte à tout élève à partir de 18 ans. Cette condition d’âge est obligatoire compte tenu des stages en milieu professionnel et notamment en service de radiologie eu égard à la législation applicable dans ce domaine.</h5>
              <h5 className={classes.description}>Pour les blocs de compétences, équivalences, passerelles et suite de parcours se rapprocher de Olivier Dufour au {global.phone_olivier}</h5>
            {/*<h3 className={classes.cardTitle}>Objectifs</h3>
               <h5 className={classes.description}>L’objectif est de vous préparer à exercer les activités suivantes dans chacun de ces domaines : </h5>
              <br/>
              <h5 className={classes.description}><b>1 - Gestion administrative:</b> Mettre en œuvre son poste de travail. Utiliser les outils et les équipements présents à l'accueil de la structure. Assurer le secrétariat. Gérer la caisse. Renseigner. Conseiller et orienter les clients en fonction de leurs attentes, leurs besoins et savoir évaluer les contextes d'urgences le cas échéant. Appliquer les savoir faire professionnels et les savoir vivre en milieu professionnel.</h5>
              <h5 className={classes.description}><b>2 - Conseil, vente et gestion:</b> Conseiller et recommander les produits en réponse aux besoins et aux attentes propriétaires/éleveurs. Savoir susciter les achats. Assurer le suivi et la gestion des stocks et les commandes. Délivrer les prescriptions médicales selon l'ordonnance établie par le praticien. Connaître les posologies, les conseils et les contradictions.</h5>
              <h5 className={classes.description}><b>3 - Hygiène et sécurité:</b> Assurer et appliquer les protocoles d'hygiène des locaux, mobiliers et des équipements, avant, pendant et après chaque situation qui rythme la vie au cabinet. Utiliser les produits et les équipements dans les règles et dans le respect des protocoles. Gérer les déchets. Gérer le risque de "contagion".</h5>
              <h5 className={classes.description}><b>4 - Soins aux animaux:</b> Surveiller les animaux en soins. Connaître, maîtriser, assurer et transmettre les bons gestes de soins et d'entretien de base des animaux.</h5>
              <h5 className={classes.description}><b>5 - Assistance et aide technique:</b> Préparer le matériel médical et les examens. Réaliser certains des examens. Assurer la contention des animaux. Assister le praticien dans le cadre d'une intervention chirurgicale ou d'un examen.</h5>
              <br/>
              <h5 className={classes.description}>Pour obtenir la certification professionnelle d’Auxiliaire de Service Vétérinaire, vous devrez prouver vos compétences dans cinq domaines. Pour cela votre centre de formation vous demande de pratiquer, de réaliser et de maîtriser chacun des compétences comprises dans chacun des domaines. Au terme de la formation, vous devrez rendre des travaux en lien direct avec chaque domaine.</h5>*/}
              <h3 className={classes.cardTitle}>La procédure de candidature</h3>
              <h5 className={classes.description}>• Je m'inscris à une session d'information.</h5>
              <h5 className={classes.description}>• Je remplis un dossier de candidature, et je le complète avec les pièces justificatives.</h5>
              <h5 className={classes.description}>• Je me rends à une session d'information qui a lieu au centre de formation où un test de positionnement et un entretien individuel seront effectués.</h5>
              <h5 className={classes.description}>• Je reçois ma réponse d'admissibilité dans un délai de 7 jours par mail.</h5>
              <h3 className={classes.cardTitle}>Programme de Formation</h3>

{/* Bloc 01 */}
<h4 className={classes.cardTitle} style={{ textTransform: 'uppercase' }}>
  Bloc 01 – Contribuer à la gestion administrative courante de l’établissement vétérinaire
  selon les protocoles de la structure
</h4>

{/* h5 original (RESTE en majuscules) */}
<h5 className={classes.description2} style={{ textTransform: 'uppercase' }}>
• ORGANISATION DE LA PLANIFICATION ET GESTION DE LA DOCUMENTATION D’UNE CLINIQUE VÉTÉRINAIRE
</h5>
{/* ancien <h6> -> <h5> en minuscules */}
<h5 className={classes.description3}>
  Fixer les rendez-vous des clients auprès de la clinique vétérinaire
</h5>
<h5 className={classes.description3} >
  Assurer la création et le suivi des dossiers clients, patients, fournisseurs
</h5>
<h5 className={classes.description3} >
  Assurer la communication écrite et visuelle interne et externe de la clinique vétérinaire
</h5>
{/* h5 original (RESTE en majuscules) */}
<h5 className={classes.description2} style={{ textTransform: 'uppercase' }}>
• CONTRIBUTION AU SUIVI DES ENTRÉES COMPTABLES ET DE STOCKS DE LA CLINIQUE VÉTÉRINAIRE
</h5>
{/* ancien <h6> -> <h5> en minuscules */}
<h5 className={classes.description3} >
  Réaliser les opérations courantes comptables et financières de la structure vétérinaire
</h5>
<h5 className={classes.description3} >
  Gérer les stocks de fournitures nécessaires au fonctionnement courant de la clinique vétérinaire
</h5>

{/* Bloc 02 */}
<h4 className={classes.cardTitle} style={{ textTransform: 'uppercase' }}>
  Bloc 02 – Assurer la relation client de la clinique vétérinaire sous l’autorité du praticien
</h4>

{/* h5 original (RESTE en majuscules) */}
<h5 className={classes.description2} style={{ textTransform: 'uppercase' }}>
• ACCUEIL TÉLÉPHONIQUE ET PHYSIQUE DES USAGERS DE LA CLINIQUE VÉTÉRINAIRE
</h5>
{/* ancien <h6> -> <h5> en minuscules */}
<h5 className={classes.description3} >
  Accueillir le client physiquement et/ou par téléphone
</h5>
<h5 className={classes.description3} >
  Accueillir physiquement l’animal au sein de la clinique vétérinaire
</h5>
{/* h5 original (RESTE en majuscules) */}
<h5 className={classes.description2} style={{ textTransform: 'uppercase' }}>
• PRISE EN CHARGE SPÉCIFIQUE ET MÉDIATION LIÉE À L’ACCUEIL DES USAGERS EN CLINIQUE VÉTÉRINAIRE
</h5>
{/* ancien <h6> -> <h5> en minuscules */}
<h5 className={classes.description3} >
  Identifier les situations d’urgence
</h5>
<h5 className={classes.description3} >
  Désamorcer les situations conflictuelles ou de tension avec le propriétaire de l’animal
</h5>
{/* h5 original (RESTE en majuscules) */}
<h5 className={classes.description2} style={{ textTransform: 'uppercase' }}>
• PRÉPARATION DES PRODUITS VÉTÉRINAIRES RÉGLEMENTÉS ET CONSEILS ET VENTE DE PRODUITS VÉTÉRINAIRES NON RÉGLEMENTÉS
</h5>
{/* ancien <h6> -> <h5> en minuscules */}
<h5 className={classes.description3}>
  Conseiller le client sur l’application des prescriptions médicales et recommandations du vétérinaire
</h5>
<h5 className={classes.description3}>
  Conduire l’entretien de conseil et de vente d’un produit ou d’un service relatif à un produit vétérinaire non réglementé (et sans prescription)
</h5>
{/* h5 original (RESTE en majuscules) */}
<h5 className={classes.description2} style={{ textTransform: 'uppercase' }}>
• SUIVI ET RESTITUTION DES INFORMATIONS POST CONSULTATION EN CLINIQUE VÉTÉRINAIRE OU LIÉES À LA FIN DE VIE DE L’ANIMAL
</h5>
{/* ancien <h6> -> <h5> en minuscules */}
<h5 className={classes.description3}>
  Assurer le suivi client post consultation
</h5>
<h5 className={classes.description3}>
  Informer le propriétaire des procédures et démarches liées à la fin de vie et décès de l’animal
</h5>

{/* Bloc 03 */}
<h4 className={classes.cardTitle} style={{ textTransform: 'uppercase' }}>
  Bloc 03 – Veiller à l’hygiène et la sécurité d’une structure vétérinaire
</h4>

{/* h5 original (RESTE en majuscules) */}
<h5 className={classes.description2} style={{ textTransform: 'uppercase' }}>
• DÉPLOIEMENT DES MESURES D’HYGIÈNE, DE NETTOYAGE DES LOCAUX ET DU MATÉRIEL DE LA CLINIQUE VÉTÉRINAIRE
</h5>
{/* ancien <h6> -> <h5> en minuscules */}
<h5 className={classes.description3}>
  Mettre en œuvre des opérations d’hygiène des locaux de la clinique vétérinaire
</h5>
<h5 className={classes.description3}>
  Désinfecter les instruments et le matériel vétérinaire
</h5>
<h5 className={classes.description3}>
  Mettre en œuvre les protocoles de biosécurité lors de la détection des animaux contagieux dans la clinique vétérinaire
</h5>
{/* h5 original (RESTE en majuscules) */}
<h5 className={classes.description2} style={{ textTransform: 'uppercase' }}>
• PRÉVENTION DES RISQUES PROFESSIONNELS PHYSIQUES, CHIMIQUES, BIOLOGIQUES, PSYCHOSOCIAUX ET ENVIRONNEMENTAUX AU SEIN D’UNE CLINIQUE VÉTÉRINAIRE
</h5>
{/* ancien <h6> -> <h5> en minuscules */}
<h5 className={classes.description3}>
  Traiter les déchets issus des activités de soins vétérinaires et de l’activité générale de la clinique
</h5>
<h5 className={classes.description3}>
  Respecter les protocoles de la radioprotection dans la structure vétérinaire, en minimisant le temps de présence et le nombre de visites dans les zones exposées identifiées
</h5>
<h5 className={classes.description3}>
  Déployer les mesures de protection individuelle et collective au sein de la clinique vétérinaire
</h5>

{/* Bloc 04 */}
<h4 className={classes.cardTitle} style={{ textTransform: 'uppercase' }}>
  Bloc 04 – Assister techniquement le vétérinaire
</h4>

{/* h5 original (RESTE en majuscules) */}
<h5 className={classes.description2} style={{ textTransform: 'uppercase' }}>
• RÉPARATION DE L’ESPACE DÉDIÉ ET DE L’ANIMAL AUX INTERVENTIONS VÉTÉRINAIRES
</h5>
{/* ancien <h6> -> <h5> en minuscules */}
<h5 className={classes.description3}>
  Préparer la zone de consultation et l’animal
</h5>
{/* h5 original (RESTE en majuscules) */}
<h5 className={classes.description2} style={{ textTransform: 'uppercase' }}>
• ASSISTANCE DU PRATICIEN DE LA STRUCTURE VÉTÉRINAIRE DANS LA RÉALISATION DES CONSULTATIONS ET DES EXAMENS COMPLÉMENTAIRES
</h5>
{/* ancien <h6> -> <h5> en minuscules */}
<h5 className={classes.description3}>
  Assister techniquement le vétérinaire lors des consultations et des examens complémentaires
</h5>
<h5 className={classes.description3}>
  Assurer la logistique des prélèvements biologiques et des données afférentes
</h5>
{/* h5 original (RESTE en majuscules) */}
<h5 className={classes.description2} style={{ textTransform: 'uppercase' }}>
• ASSISTANCE DU PRATICIEN DE LA STRUCTURE VÉTÉRINAIRE DANS LE CADRE DE LA RÉALISATION DE SES INTERVENTIONS CHIRURGICALES
</h5>
{/* ancien <h6> -> <h5> en minuscules */}
<h5 className={classes.description3}>
  Assister le ou les vétérinaires lors de l’intervention chirurgicale
</h5>
<h5 className={classes.description3}>
  Suivre les paramètres vitaux monitorés pendant l’intervention chirurgicale
</h5>
{/* h5 original (RESTE en majuscules) */}
<h5 className={classes.description2} style={{ textTransform: 'uppercase' }}>
• SUIVI DE L’ANIMAL PENDANT L’HOSPITALISATION ET LA CONVALESCENCE : SURVEILLANCE DES PARAMÈTRES CLINIQUES ET ADMINISTRATION OU TRANSMISSION AU PROPRIÉTAIRE DES TRAITEMENTS PRESCRITS PAR LE VÉTÉRINAIRE
</h5>
{/* ancien <h6> -> <h5> en minuscules */}
<h5 className={classes.description3}>
  Assurer le suivi de l’animal post opération
</h5>
<h5 className={classes.description3}>
  Prodiguer les soins aux animaux hospitalisés dans les limites du périmètre métier de l’asv
</h5>
<h5 className={classes.description3}>
  Restituer les directives du vétérinaire en matière de traitements et soins post opératoires à prodiguer à l’animal
</h5>


       
              <br/>
              

              <p className={classes.description}></p>
              <h3 className={classes.cardTitle}>Obtention de la certification professionnelle d'auxiliaire de service vétérinaire</h3> 
              <h5 className={classes.description}>L’ensemble des évaluations se cumule pour l’obtention de la certification professionnelle. La note globale de fin de formation sera constituée : </h5>
              <br/>
              <h5 className={classes.description}>✓ De notes du contrôle continu des périodes 1 & 2, représentant 10 % de la note globale.</h5>
              <h5 className={classes.description}>✓ De notes du premier et du second examen représentant respectivement 10 % de la note globale.</h5>
              <h5 className={classes.description}>✓ De l’évaluation des maîtres de stage au terme de la première année représentant 10 % de la note globale.</h5>
              <h5 className={classes.description}>✓ Des notes du troisième examen représentant 20 % de la note globale.</h5>
              <h5 className={classes.description}>✓ De l’évaluation des différents travaux de compétences, du rapport d’activité et de la soutenance devant le jury représentant 30 % de la note globale.</h5>
              <br/>
              <h5 className={classes.description}>Pour ceux dont les résultats ne sont pas conformes, des épreuves de rattrapages sont organisées.</h5>
              <h3 className={classes.cardTitle}>Indicateurs de résultats</h3>
              <h5 className={classes.description}>· Taux de réussite : 80%</h5>
              <h5 className={classes.description}>· Taux de poursuite d'études : pas de poursuite d’études</h5>
              <h5 className={classes.description}>· Taux d’insertion à 6 mois : 60% - 6 insérés sur 10 (dont 6 insérés dans l’emploi)</h5>
              <h5 className={classes.description}>· Taux de satisfaction global : 76%</h5>
              <h5 className={classes.description}>· Taux global d’accomplissement : 100%</h5>
              <h5 className={classes.description}>· Taux d’abandons : 0%</h5>
              <br/>
              <p className={classes.descriptionJob}>Nos chiffres ne sont pas inscrits sur Insert Jeunes et nous ne communiquons pas sur la valeur ajoutée. Seul les établissements publics et les établissements d'enseignements privés sous contrats sont concernés.</p>
              <h3 className={classes.cardTitle}>Type de Diplôme</h3>
                <h5 className={classes.description}>Certification professionnelle de niveau 4, numéro 35138, d'Auxiliaire de Service Vétérinaire délivrée par l'IPAM selon arrêté du 16 décembre 2020 , portant inscription au Répertoire National des Certifications Professionnelles ( RNCP ) par France Compétences , actif jusqu’au 16 décembre 2023. Nous avons établi un projet de certification professionnelle actualisé, qui suit son cours afin d'obtenir le renouvellement de cette reconnaissance.</h5>
              <h3 className={classes.cardTitle}>Débouchés</h3>
                <GridContainer>
                  <GridItem xs={12} sm={7} md={7}>
                    <h5 className={classes.description}>✓ Auxiliaire de service vétérinaire</h5>
                    <h5 className={classes.description}>✓ Assistant de structure vétérinaire</h5>
                    <h5 className={classes.description}>✓ Auxiliaire spécialisé vétérinaire</h5>
                    <h5 className={classes.description}>✓ Vendeur en animalerie</h5>
                    <h5 className={classes.description}>✓ Postes en laboratoires d'analyses vétérinaires</h5>
                  </GridItem>
                  <GridItem xs={12} sm={5} md={5}>
                    <h5 className={classes.description}>✓ Postes pour service d'urgences vétérinaires</h5>
                    <h5 className={classes.description}>✓ Postes brigades canines de la police ou de la gendarmerie</h5>
                    <h5 className={classes.description}>✓ Postes au sein d'une pension pour animaux</h5>
                  </GridItem>
                </GridContainer>
              </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <Card
                    backgroundSupveto
                    style={{ backgroundImage: `url(${imgSecMed})` }}
                  >
                    <CardBody background>
                      <h3 className={classes.cardTitleWhite}>
                        Vous souhaitez travailler dans le secteur animalier?
                      </h3>
                      <br/>
                      <h3 className={classes.cardTitleWhite}>
                        Rendez vous à notre prochaine journée portes ouvertes
                      </h3>
                      <br/>
                      <br/>
                      <p className={classes.cardDescriptionWhite}>
                        <b>Impasse du Docteur Charles Barnier</b>
                      </p>
                      <p className={classes.cardDescriptionWhite}>
                        <b>83000 Toulon</b>
                      </p>
                      <br/>
                      <br/>
                      {inscriptionEnabled ?
                      <Button color="white" round onClick={() => {setModalInscription(true)}}>
                        Je m'inscris
                      </Button> : <h6>pas de session prévue pour l'instant</h6>}
                      <br/>
                      <br/>
                    </CardBody>
                  </Card>
                  <div className={classes.buttonCenter}>
                  <Button color="supveto" round onClick={() => {setModalDownload(true)}}>
                    <GetAppIcon/>Télécharger la documentation
                  </Button>
                  </div>
                  <h3 className={classes.cardTitle}>Durée du cursus</h3>
                  <h5 className={classes.description}>Formation en <b>24 mois</b></h5>
                  <h5 className={classes.description}><b>1030 heures</b> de cours et examens en centre</h5>
                  <h5 className={classes.description}><b>28 semaines</b> de stage en entreprise</h5>
                  <h3 className={classes.cardTitle}>Méthodes pédagogiques</h3>
                  <h5 className={classes.description}>Cours théoriques</h5>
                  <h5 className={classes.description}>Travaux dirigés et Travaux pratiques</h5>
                  <h3 className={classes.cardTitle}>Equipe Pédagogique</h3>
                  <h5 className={classes.description}><b>Carine TRIPODI</b></h5>
                  <p className={classes.descriptionJob}>Conseillère formation</p>
                  <h5 className={classes.description}><b>Camille DUPAIN</b></h5>
                  <p className={classes.descriptionJob}>Assistante de Direction</p>
                  <h5 className={classes.description}><b>Olivier DUFOUR</b></h5>
                  <p className={classes.descriptionJob}>Directeur - Référent Handicap</p>
                  <h3 className={classes.cardTitle}>Tarif (sur 24 mois)</h3>
                  <h5 className={classes.description}>Frais d'inscription: <b>300€</b></h5>
                  <h5 className={classes.description}>Coût de la formation: <b>6750€</b></h5>
                  <h3 className={classes.cardTitle}>Public visé</h3>
                  <h5 className={classes.description}>Demandeur d'emploi</h5>
                  <h5 className={classes.description}>Etudiant</h5>
                  <h5 className={classes.description}>Salarié en reconversion</h5>
                  </GridItem>
              </GridContainer>
          </div>
        </div>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={modalDownload}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setModalDownload(false)}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
          maxWidth="md"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
          <h4 className={classes.modalTitle}>Téléchargez la documentation</h4>
          <p className={classes.description}>Veuillez remplir le formulaire pour accéder au téléchargement</p>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                labelText="Nom"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, nom: e.target.value });errors["name"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["name"]}</p>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                labelText="Prénom"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, prenom: e.target.value });errors["prenom"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["prenom"]}</p>
            </GridItem>
          </GridContainer>
              <CustomInput
                labelText="Téléphone"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, phone: e.target.value });errors["phone"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["phone"]}</p>
              <CustomInput
                labelText="Email"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, email: e.target.value });errors["email"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["email"]}</p>
              <FormControlLabel
                control={
                  <Checkbox
                    color="default"
                    onChange={handleCheckbox}
                  />
                }
                label="J'accepte l'enregistrement de mes informations dans la base de données de l'école."
              />
              <p style={{color: "red",fontSize: "12px"}}>{errors["checkbox"]}</p>
              <p className={classes.rgpd}>Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par Ecosup Toulon pour vous tenir informés d'informations importantes relatives à votre admissibilité. Elles sont conservées pendant 1 an et sont destinées au service des admissions. Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit d'accès aux données vous concernant et les faire rectifier en contactant : contact@ecosup-toulon.fr Nous vous informons de l’existence de la liste d'opposition au démarchage téléphonique « Bloctel », sur laquelle vous pouvez vous inscrire ici : https://conso.bloctel.fr/</p>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button onClick={() => {download()}} color="supveto" round>
              {isLoading ? "Veuillez patienter ... " : "Téléchargement"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={modalInscription}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setModalInscription(false)}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
          maxWidth="md"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
          <h4 className={classes.modalTitle}>Réunion d'informations à Toulon</h4>
          <p className={classes.features}>Lieu de la réunion : <b>Ecosup-Toulon</b></p>
          <p className={classes.features}>177 Boulevard Charles Barnier, 83000 Toulon</p>
          <p className={classes.features}>(accés depuis l'impasse Barnier)</p>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                Session
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={sessionSelect}
                onChange={handleSessionSelect}
                inputProps={{
                  name: "simpleSelect",
                  id: "simple-select"
                }}
                >
                {sessions.map((session,index) => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value={session.id}
                    key={index}
                  >
                    {session.text}
                  </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <p style={{color: "red",fontSize: "12px"}}>{errors["session"]}</p>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                labelText="Nom"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, nom: e.target.value });errors["name"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["name"]}</p>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                labelText="Prénom"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, prenom: e.target.value });errors["prenom"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["prenom"]}</p>
            </GridItem>
          </GridContainer>
              <CustomInput
                labelText="Téléphone"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, phone: e.target.value });errors["phone"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["phone"]}</p>
              <CustomInput
                labelText="Email"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, email: e.target.value });errors["email"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["email"]}</p>
              <FormControlLabel
                control={
                  <Checkbox
                    color="default"
                    onChange={handleCheckbox}
                  />
                }
                label="J'accepte l'enregistrement de mes informations dans la base de données de l'école."
              />
              <p style={{color: "red",fontSize: "12px"}}>{errors["checkbox"]}</p>
              <p className={classes.rgpd}>Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par Ecosup Toulon pour vous tenir informés d'informations importantes relatives à votre admissibilité. Elles sont conservées pendant 1 an et sont destinées au service des admissions. Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit d'accès aux données vous concernant et les faire rectifier en contactant : contact@ecosup-toulon.fr Nous vous informons de l’existence de la liste d'opposition au démarchage téléphonique « Bloctel », sur laquelle vous pouvez vous inscrire ici : https://conso.bloctel.fr/</p>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button onClick={() => {registerRDV()}} color="supveto" round>
              {isLoading ? "Veuillez patienter ... " : "Inscription"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Snackbar open={successRegister} autoHideDuration={5000} onClose={handleCloseRegister}>
        <Alert onClose={handleCloseRegister} severity="success">
          Votre inscription a bien été enregistrée!
        </Alert>
      </Snackbar>
      <SectionFooter date={"15 Août 2024"}/>
    </div>
  );
}
