import React from "react";
import { useState, useEffect } from "react";
import { useHistory} from "react-router-dom";
import axios from "axios";
import { CSVLink } from "react-csv";
import { DateTime } from "luxon";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "components/CustomInput/CustomInput.js";
// react plugin for creating charts
// @material-ui/core
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';

import Table from "components/Table/Table.js";

import styles from "assets/jss/material-kit-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function ContratPage() {

  const headers = [
    "Titre",
    "Description",
    "Formation",
    "Durée",
    "Type",
    "Date"
  ];

  const [isLoading, setIsLoading] = useState(false);

  function handleValidation(){

    let formIsValid = true;
    let errors = {};

    let formation = `${data.formation}`;
    if(formation === ""){
      formIsValid = false;
      errors["formation"] = "*Veuillez sélectionner une formation";
   }

   let type = `${data.type}`;
   if(type === ""){
    formIsValid = false;
    errors["type"] = "*Veuillez sélectionner un type de contrat";
 }

    let titre = `${data.titre}`;
    if(titre.trim() === ""){
       formIsValid = false;
       errors["titre"] = "*Veuillez entrer un intitulé";
    }

    let description = `${data.description}`;
    if(description.trim() === ""){
       formIsValid = false;
       errors["description"] = "*Veuillez entrer une description";
    }

    let duree = `${data.duree}`;
    if(duree.trim() === ""){
       formIsValid = false;
       errors["duree"] = "*Veuillez entrer une durée";
    }

   setErrors(errors);
   //console.log(errors);
   return formIsValid;
  }

  function createContract() {
    let isValid = handleValidation();
    if(isValid){
      setIsLoading(true);
      let userjwt = localStorage.getItem('jwt');
      const dataForm = new FormData();
      dataForm.append('data', JSON.stringify({
           profil: `${data.titre}`,
           description: `${data.description}`,
           duree: `${data.duree}`,
           formation: `${data.formation}`,
           type: `${data.type}`
         }))

      axios.post(`${global.API_URL}/contrats`,dataForm,{
        headers: {
          Authorization:`Bearer ${userjwt}`,
        },
      }).then(function(response){
      setData({ titre:"",description:"",duree:"",formation: "",type:""});
      setFormationSelect("");
      setTypeSelect("");
      setIsLoading(false);
      retrieveContrats();
    })
    .catch(function(){
      console.log('Fail!');
      setIsLoading(false);
    });
    }
  }

  const [contracts, setContracts] = useState([]);
  const history = useHistory();

  const [data, setData] = useState({ titre:"",description:"",duree:"",formation: "",type:""});
  const [errors, setErrors] = useState({});

  const [formationSelect, setFormationSelect] = React.useState("");
  const handleFormation = event => {
    let code = event.target.value;
    setFormationSelect(event.target.value);
    if(code==="1"){setData({ ...data, formation: 1 })}
    if(code==="2"){setData({ ...data, formation: 2 })}
    if(code==="3"){setData({ ...data, formation: 3 })}
    if(code==="4"){setData({ ...data, formation: 4 })}
    if(code==="5"){setData({ ...data, formation: 5 })}
    if(code==="6"){setData({ ...data, formation: 6 })}
    errors["formation"]=""
  }

  const [typeSelect, setTypeSelect] = React.useState("");
  const handleType = event => {
    let code = event.target.value;
    setTypeSelect(event.target.value);
    if(code==="1"){setData({ ...data, type: 1 })}
    if(code==="2"){setData({ ...data, type: 2 })}
    errors["type"]=""
  }

  function retrieveContrats(){
    let userjwt = localStorage.getItem('jwt');
    //console.log(userjwt);
    if(userjwt!=null){
      axios.get(`${global.API_URL}/contrats`, {
        headers: {
          Authorization:
          `Bearer ${userjwt}`,
        },
      }).then(res => {
        let listMessages = [];
        for (let [index,message] of res.data.entries()) {
          let datestr_created = DateTime.fromISO(`${res.data[index].created_at}`);
          let date = datestr_created.setLocale('fr').toLocaleString();
          let type="";
          if(message.type.id===1){
            type="professionnalisation";
          }
          if(message.type.id===2){
            type="apprentissage";
          }
          let formation="";
          if(message.formation.id===3){
            formation="assistante_dentaire";
          }
          if(message.formation.id===2){
            formation="auxiliaire_veterinaire";
          }
          if(message.formation.id===1){
            formation="secretaire_medicale";
          }
          if(message.formation.id===4){
            formation="structure_animaliere";
          }
          if(message.formation.id===5){
            formation="aepe";
          }
          if(message.formation.id===6){
            formation="assistant_juridique";
          }
          let tmpMessage = [`${message.profil}`,`${message.description}`,formation,`${message.duree}`,type,date,
          <Button justIcon size="sm" simple color="danger" onClick={() => deleteEntry(message.id)}>
            <DeleteOutlineIcon/>
          </Button>];
          listMessages.push(tmpMessage);
        }
        setContracts(listMessages);
        console.log(listMessages);
      },[]);
    }else{
      console.log('User not logged!!!');
      history.push('/');
    }
  }

  const deleteEntry = value => {
    let userjwt = localStorage.getItem('jwt');
    const apiUrl = `${global.API_URL}/contrats/${value}`;
    fetch(apiUrl, {
      method: "DELETE",
      headers: userjwt && { Authorization: `Bearer ${userjwt}` }})
      .then(function(response){
        retrieveContrats();
      }).catch(function(response){
         console.log('Failed to delete project');
      });
  }

  useEffect(() => {
    retrieveContrats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[history]);

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
      <Card>
          <CardHeader color="info">
              <GridContainer justify="center" alignItems="center">
              <GridItem xs={12} sm={8} md={8}>
              <h4 className={classes.cardTitleWhite}>Nouvelle offre d'emploi</h4>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
              <div className={classes.buttonRight}><Button color="white" onClick={() => {createContract()}} round><AddIcon/> {isLoading ? "Veuillez patienter ... " : "Ajouter"}</Button></div>
              </GridItem>
              </GridContainer>
          </CardHeader>
          <CardBody>
              <GridContainer direction="row"
                justify="center"
                alignItems="bottom">
                <GridItem xs={12} sm={4} md={4}>
                  <CustomInput
                      labelText="Intitulé"
                      id="float"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: (e) => {setData({ ...data, titre: e.target.value });errors["titre"]=""},
                        required: true}}
                    />
                  <p style={{color: "red",fontSize: "12px"}}>{errors["titre"]}</p>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                  <CustomInput
                      labelText="Description"
                      id="float"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: (e) => {setData({ ...data, description: e.target.value });errors["description"]=""},
                        required: true}}
                    />
                  <p style={{color: "red",fontSize: "12px"}}>{errors["description"]}</p>
                  </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Formation
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={formationSelect}
                    onChange={handleFormation}
                    inputProps={{
                      name: "simpleSelect",
                      id: "simple-select"
                    }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="3"
                      >
                        Assistante Dentaire
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="1"
                      >
                        Secrétaire Médicale
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="2"
                      >
                        Auxiliaire de Service Vétérinaire
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="5"
                      >
                        Assistant(e) éducatif petite enfance
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="6"
                      >
                        Assistant(e) juridique
                      </MenuItem>
                    </Select>
                    </FormControl>
                    <p style={{color: "red",fontSize: "12px"}}>{errors["formation"]}</p>
                  </GridItem>
                  
                  <GridItem xs={12} sm={4} md={4}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Type
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={typeSelect}
                    onChange={handleType}
                    inputProps={{
                      name: "simpleSelect",
                      id: "simple-select"
                    }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="1"
                      >
                        Professionnalisation
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="2"
                      >
                        Apprentissage
                      </MenuItem>
                    </Select>
                    </FormControl>
                    <p style={{color: "red",fontSize: "12px"}}>{errors["type"]}</p> 
                  </GridItem> 
                  
                  <GridItem xs={12} sm={4} md={4}>
                    <CustomInput
                      labelText="Durée"
                      id="float"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: (e) => {setData({ ...data, duree: e.target.value });errors["duree"]=""},
                        required: true}}
                    />
                  <p style={{color: "red",fontSize: "12px"}}>{errors["duree"]}</p>
                  </GridItem>
              </GridContainer>
          </CardBody>
      </Card>
      <br/>
        <Card>
          <CardHeader color="info">
              <GridContainer justify="center" alignItems="center">
              <GridItem xs={12} sm={8} md={8}>
              <h4 className={classes.cardTitleWhite}>Liste des offres d'emploi publiées</h4>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
              <div className={classes.buttonRight}><CSVLink data={contracts} headers={headers}><Button color="white" round>Télécharger le fichier Excel</Button></CSVLink></div>
              </GridItem>
              </GridContainer>
          </CardHeader>
          <CardBody>
          <Table
            striped
            tableHead={[
              "Titre",
              "Description",
              "Formation",
              "Durée",
              "Type",
              "Date"
            ]}
            tableData={contracts}
            customCellClasses={[
              classes.textLeft,
              classes.textLeft,
              classes.textLeft
            ]}
            customClassesForCells={[0, 1, 2]}
            customHeadCellClasses={[
              classes.textLeft,
              classes.textLeft,
              classes.textLeft
            ]}
            customHeadClassesForCells={[0, 1, 2]}
          />
        </CardBody>
      </Card>
    </GridItem>
    </GridContainer>
  );
}
