

/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CircularProgress from '@material-ui/core/CircularProgress';

// sections for this page
import SectionDescription from "views/PresentationPage/Sections/SectionDescription.js";
import SectionFooter from "views/footer/PageFooter.js"

import presentationStyle from "assets/jss/material-kit-pro-react/views/echosup/ProfessionnalisationStyle.js";

import logo_ecosup from "assets/img/ecosup.png"

import logo_oeuf from "assets/img/visuel_oeuf_2023.png"
import ProfessionnalisationPage from "./ProfessionnalisationPage";

const useStyles = makeStyles(presentationStyle);

export default function FormOfferPage() {

  const formationTypeMap = {
    assistante_dentaire: "Assistant(e) dentaire",
    aepe: "Accompagnant(e) éducatif petite enfance",
    assistant_juridique: "Assistant(e) juridique",
    auxiliaire_veterinaire: "Auxiliaire de service vétérinaire",
    secretaire_medicale: "Secrétaire médical(e)",
  };

  const typeTypeMap = {
    professionnalisation: "Professionnalisation",
    apprentissage: "Apprentissage",
  };


  const [isLoading, setIsLoading] = useState(false);
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    setIsLoading(true)
    axios.get(`${global.API_URL}/contrats`)
      .then(res => {
        console.log(res.data);
        let offers = res.data;
        offers.sort((a, b) => new Date(a.created_at) > new Date(b.created_at) ? -1 : 1);

        setOffers(offers);
        console.log(offers)
        setIsLoading(false)
      });
  }, []);

  const classes = useStyles();
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 50,
          color: "white"
        }}
      />
      <Parallax
        image={require("assets/img/form.jpg")}
        className={classes.parallax}
        filter="dark"
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <GridContainer justify="center" alignItems="center">
                  <GridItem xs={8} sm={4} md={4}>
                    <img
                      src={logo_ecosup} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                    <h2 className={classes.title}>
                      Nos offres d'emploi
                    </h2>
                  </GridItem>
                </GridContainer>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
  <div className={classes.section}>
    <div className={classes.container}>
      {isLoading ? (
        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={1} md={1}>
            <CircularProgress color="inherit" />
          </GridItem>
          <GridItem xs={12} sm={11} md={11}>
            <h3 className={classes.titleLoading}>
              Chargement des offres d'emploi. Veuillez Patientez ...
            </h3>
          </GridItem>
        </GridContainer>
      ) : offers && offers.length > 0 ? (
        offers.map((offer) => {
          const createdAt = new Date(offer.created_at).toLocaleDateString();
          const formationType =
            offer.formation && formationTypeMap[offer.formation.type]
              ? formationTypeMap[offer.formation.type]
              : offer.formation && offer.formation.type;
          const typeType =
            offer.type && typeTypeMap[offer.type.type]
              ? typeTypeMap[offer.type.type]
              : offer.type && offer.type.type;
          return (
            <div key={offer.id} style={{ marginBottom: "20px" }}>
              <h2 className={classes.title_sub}>{offer.profil}</h2>
              <h4 className={classes.description_sub2}>
                Description: {offer.description}
              </h4>
              <p className={classes.description_sub}>Durée: {offer.duree}</p>
              <p className={classes.description_sub}>
                Formation: {formationType}
              </p>
              <p className={classes.description_sub}>Type: {typeType}</p>
              <br />
              <p className={classes.description_sub2}>
                Postée le: <b>{createdAt}</b>
              </p>
              <hr style={{ marginTop: "20px", marginBottom: "20px" }} />
            </div>
          );
        })
      ) : (
        <h3 className={classes.titleLoading}>
          Aucune offre d'emploi trouvée.
        </h3>
      )}
    </div>
  </div>
</div>
      <SectionFooter/>
    </div>
  );
}

