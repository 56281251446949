/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import ViewDay from "@material-ui/icons/ViewDay";
import Dns from "@material-ui/icons/Dns";
import Build from "@material-ui/icons/Build";
import ListIcon from "@material-ui/icons/List";
import People from "@material-ui/icons/People";
import Assignment from "@material-ui/icons/Assignment";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import Chat from "@material-ui/icons/Chat";
import Call from "@material-ui/icons/Call";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
import AccountBalance from "@material-ui/icons/AccountBalance";
import ArtTrack from "@material-ui/icons/ArtTrack";
import ViewQuilt from "@material-ui/icons/ViewQuilt";
import LocationOn from "@material-ui/icons/LocationOn";
import Fingerprint from "@material-ui/icons/Fingerprint";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Store from "@material-ui/icons/Store";
import AccountCircle from "@material-ui/icons/AccountCircle";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Layers from "@material-ui/icons/Layers";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import LineStyle from "@material-ui/icons/LineStyle";
import Error from "@material-ui/icons/Error";

import SchoolIcon from '@material-ui/icons/School';
import AssignmentIcon from '@material-ui/icons/Assignment';

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";
import CallIcon from '@material-ui/icons/Call';
import BusinessIcon from '@material-ui/icons/Business';
import HomeIcon from '@material-ui/icons/Home';
import EventIcon from '@material-ui/icons/Event';
import LanguageIcon from '@material-ui/icons/Language';

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScroll = (e, target) => {
    if (window.location.pathname === "/sections") {
      var isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      );
      if (isMobile) {
        // if we are on mobile device the scroll into view will be managed by the browser
      } else {
        e.preventDefault();
        var targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
      }
    }
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function() {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };
  var onClickSections = {};

  const { dropdownHoverColor } = props;
  const classes = useStyles();
  return (
    <List className={classes.list + " " + classes.mlAuto}>
      <ListItem className={classes.listItem}>
          <Button

            className={
              classes.navLink
            }
            color="transparent"
          >
          <Link to="/" className={classes.textLink}>
          <HomeIcon/> Accueil
          </Link>
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          className={
            classes.navLink
          }
          color="transparent"
        >
        <Link to="/reseau" className={classes.textLink}>
        <LanguageIcon/> Réseau
        </Link>
      </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Formations"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={SchoolIcon}
          dropdownList={[
            <Link to={{ pathname: "https://esad-dentaire.com/" }} target="_blank" className={classes.dropdownLink}>
               Assistante dentaire
            </Link>,
            <Link to={{ pathname: "https://vidal-formation.net/formation-en-alternance/" }} target="_blank" className={classes.dropdownLink}>
               Secretaire médicale
            </Link>,
            <Link to="/auxiliaire-veterinaire" className={classes.dropdownLink}>
               Auxiliaire de service vétérinaire
            </Link>,
            // <Link to="/structure-animaliere" className={classes.dropdownLink}>
            //    Chargé(e) de Gestion et de Management en Structure Animalière
            // </Link>,
            <Link to="/aepe" className={classes.dropdownLink}>
              Accompagnant(e) éducatif petite enfance
            </Link>,
            <Link to="/juridique" className={classes.dropdownLink}>
                Assistant(e) Juridique
            </Link>
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
          <Button
            className={
              classes.navLink
            }
            color="transparent"
          >
          <Link to="/inscription" className={classes.textLink}>
          <AssignmentIcon /> Inscriptions
          </Link>
          </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          className={
            classes.navLink
          }
          color="transparent"
        >
        <Link to="/actu" className={classes.textLink}>
        <EventIcon /> Actualités
          </Link>
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Espace entreprise"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={BusinessIcon}
          dropdownList={[
            <Link to="/apprentissage" className={classes.dropdownLink}>
               Apprentissage
            </Link>,
            <Link to="/professionnalisation" className={classes.dropdownLink}>
               Professionnalisation
            </Link>,
            <Link to="/proposition" className={classes.dropdownLink}>
               Nos offres d'emploi
            </Link>
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          className={
            classes.navLink
          }
          color="transparent"
        >
        <Link to="/qualiopi" className={classes.textLink}>
        <Assignment  /> Certification Qualiopi
        </Link>
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          className={
            classes.navLink
          }
          color="transparent"
        >
        <Link to="/contact" className={classes.textLink}>
        <CallIcon  /> Contact
        </Link>
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem md={3} sm={4}>
            <a
              href="https://www.facebook.com/EcoSup-Toulon-731805564203182"
              target="_self"
              className={classes.socialmedia}
            >
            <Button color="facebook" round justIcon>
              <i className="fab fa-facebook-f" />
            </Button>
            </a>
          </GridItem>
          <GridItem md={3} sm={4}>
            <a
              href="https://www.instagram.com/ecosup_toulon/"
              target="_self"
              className={classes.socialmedia}
            >
            <Button color="instagram" round justIcon>
              <i className="fab fa-instagram" />
            </Button>
          </a>
          </GridItem>
          <GridItem md={3} sm={4}>
            <a
              href="https://www.linkedin.com/company/ecosup-toulon/"
              target="_self"
              className={classes.socialmedia}
            >
            <Button color="linkedin" round justIcon>
              <i className="fab fa-linkedin-in" />
            </Button>
          </a>
          </GridItem>
        </GridContainer>
      </ListItem>
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ])
};
