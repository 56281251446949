/*eslint-disable*/
import React from "react";
import { useEffect } from "react";
import axios from "axios";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// sections for this page
import SectionDescription from "views/PresentationPage/Sections/SectionDescription.js";
import SectionPromesse from "views/PresentationPage/Sections/SectionPromesse.js";
import SectionFooter from "views/footer/PageFooter.js"

import { Link } from "react-router-dom";

import presentationStyle from "assets/jss/material-kit-pro-react/views/echosup/presentationStyle.js";

import logo_ecosup from "assets/img/ecosup.png"

const useStyles = makeStyles(presentationStyle);

export default function PresentationPage() {

  useEffect(() => {
    axios.get(`${global.API_URL}/articles`).then(res => {
      console.log(res.data);
    });
  },[history]);

  const classes = useStyles();
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 50,
          color: "white"
        }}
      />
      <Parallax
        image={require("assets/img/toulon4.jpg")}
        className={classes.parallax}
        filter="dark"
      >
        <div className={classes.containerAccueil}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <GridContainer justify="center" alignItems="flex-end">
                  <GridItem xs={10} sm={10} md={4}>
                    <img
                      src={logo_ecosup} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                    <h3 className={classes.titleright}>
                      Une formation - Un métier
                    </h3>
                  </GridItem>
                  <GridItem xs={0} sm={0} md={3}>
                  </GridItem>
                  <GridItem xs={11} sm={11} md={5}>
                    <h4 className={classes.titleQuote}>
                    <i>"Notre projet pédagogique est fondé sur une interaction entre les étudiants et les enseignants professionnels et spécialistes de leur matière."</i>
                  </h4>
                    <h3 className={classes.titleQuote}>
                      <b>Olivier Dufour</b>, Directeur.
                    </h3>
                  </GridItem>
                </GridContainer>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.cards)}>
        <SectionDescription />
        <SectionPromesse />
      </div>

      <SectionFooter date={"2 Avril 2025"}/>
    </div>
  );
}
